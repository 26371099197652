<template>
  <div class="bg-white w-full p-3">
    <UsersTable />
  </div>
</template>

<script>
import UsersTable from "@/modules/xpbx/components/users/UsersTable/UsersTable.vue";

export default {
  name: "Users",

  components: {
    UsersTable,
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>